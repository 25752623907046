<script lang="ts" setup>
import { vMaska } from 'maska/vue';
import type { PropType } from 'vue';
import { computed, reactive, readonly, ref, toRefs } from 'vue';
import { onClickOutside } from '#imports';

const props = defineProps({
  placeholder: {
    type: String,
    default: '',
  },
  error: {
    type: [String, Boolean],
    default: false,
  },
  hasCleanIcon: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    default: '',
  },
  mask: {
    type: [String, Array] as PropType<string | string[]>,
    default: '',
  },
  type: {
    type: String as PropType<'text' | 'password' | 'number'>,
    default: 'text',
  },
  nonFocusable: {
    type: Boolean,
    default: false,
  },
  expandable: {
    type: Boolean,
    default: false,
  },
  inputClass: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['keyup', 'focus', 'blur', 'change']);

const { type, expandable } = toRefs(props);

const model = defineModel({
  type: [String, Number] as PropType<string | number>,
  required: true,
});

const inputFocused = ref(false);

const inputRef = ref<HTMLInputElement | null>(null);
const wrapperRef = ref<HTMLElement | null>(null);

defineExpose({
  inputRef,
});

const inputType = computed(() => {
  if (type.value === 'number') {
    return 'number';
  }

  if (type.value === 'password') {
    return 'password';
  }

  return 'text';
});

const attrs = computed(() => ({
  tabindex: props.nonFocusable ? '-1' : undefined,
}));

const isExpanded = ref(!!(expandable.value && model.value));

const wrapperStyles = computed(() => {
  let expandedClass = '';

  if (expandable.value) {
    if (isExpanded.value) {
      expandedClass = '';
    }
    else {
      expandedClass = 'w-14! cursor-pointer!';
    }
  }

  return expandedClass;
});

const inputStyles = computed(() => {
  if (type.value === 'password' && model.value) {
    return 'c-green-pure font-[Verdana] tracking-widest';
  }

  return ' placeholder-text-secondary c-text-regular';
});

function switchExpand() {
  if (isExpanded.value) {
    collapse();
  }
  else {
    expand();
  }
}

function expand() {
  if (expandable.value) {
    isExpanded.value = true;

    inputRef.value?.focus();
  }
}

function collapse() {
  if (expandable.value) {
    if (model.value && isExpanded.value) {
      return;
    }

    isExpanded.value = false;
  }
}

function clear() {
  model.value = '';
}

onClickOutside(wrapperRef, () => {
  collapse();
});

const calculatedClasses = computed(() => {
  const base = `h-10 rounded-md border-1px text-input flex items-center cursor-text transition-width-300`;

  let color = 'border-input-stroke-idle hover:border-input-stroke-hover';

  if (props.error) {
    color += ' border-input-stroke-error!';
  }

  if (inputFocused.value && !props.error) {
    color = ' border-input-stroke-hover!';
  }

  if (props.disabled) {
    color = 'border-stroke-secondary! c-text-inactive cursor-not-allowed';
  }

  return `${base} ${color} ${wrapperStyles.value}`;
});

const maskOptions = reactive({
  mask: props.mask,
  eager: true,
});
</script>

<template>
  <div
    ref="wrapperRef"
    :class="calculatedClasses"
    tabindex="-1"
    @click="inputRef?.focus(); !isExpanded && expand()"
  >
    <div
      v-if="$slots.prependedIcon"
      class="pl-4 pr-2 h-full flex items-center justify-center"
      :class="[expandable && !model && 'cursor-pointer']"
      @click.stop="switchExpand"
    >
      <slot name="prependedIcon" />
    </div>

    <input
      :id="id"
      ref="inputRef"
      v-model="model"
      v-maska="maskOptions"
      v-bind="attrs"
      :name="name"
      :type="inputType"
      :readonly="props.readonly || disabled"
      :placeholder="placeholder"
      class="outline-none! w-full"
      :class="[
        $slots.prependedIcon ? 'pl-1' : 'pl-4',
        $slots.actionButton ? '' : 'pr-4',
        inputStyles,
        inputClass,
        !isExpanded && expandable ? 'opacity-0 pointer-events-none' : '',
        props.disabled ? 'cursor-not-allowed' : '',
      ]"
      @focus="inputFocused = true; $emit('focus', $event);"
      @blur="inputFocused = false; $emit('blur', $event);"
      @keyup="$emit('keyup', $event)"
      @change="$emit('change', $event)"
    >

    <div
      v-if="$slots.actionButton"
      class="mr-1.25 ml-1.5"
      :class="[
        !isExpanded && expandable ? 'opacity-0 pointer-events-none' : '',
      ]"
    >
      <slot name="actionButton" />
    </div>
    <button
      v-if="!$slots.actionButton && hasCleanIcon && model"
      class="
        mr-2 ml-1.5 w-7.5 h-7.5 flex items-center justify-center c-icons-primary
        hover:bg-green-pure/12 hover:c-green-pure transition-color,background-color,opacity-200
      "
      :class="[
        !isExpanded && expandable ? 'opacity-0 pointer-events-none' : '',
      ]"
      @click.stop="clear"
    >
      <Icon name="ic:round-close" class="w-6 h-6" />
    </button>
  </div>
</template>

<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
